import React, { Component } from 'react';

class DidLeaveUnity extends Component {

    constructor(props) {
      super(props);

      this.state = {      
          starHover: -1,
          feedbackValue: "",
          feedbackSubmitted: false
      }

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
      this.setState({feedbackValue: event.target.value});
    }

    handleSubmit(event) {
      event.preventDefault();
      this.props.submitFeedback(this.state.feedbackValue);

      this.setState({
        feedbackSubmitted: true
      });
    }
  
    render() {

      const feedbackForm = 
        <form onSubmit={this.handleSubmit}  text-align="center" style={{"width": "100%"}} >
          <div text-align="center" style={{"width": "100%", "text-align": "center"}} >
            <label style={{"display": "block"}} >
              <textarea 
                placeholder="The biggest issue was..." 
                value={this.state.feedbackValue} 
                onChange={this.handleChange}
                style={{"resize":"none", "height": "250px", "width": "400px", "font-family": "inherit"}} />
            </label>
            <input type="submit" value="Submit" style={{
              "display": "inline",
              "color": "#000",
              "background-color": "#ffc700",
              "border-color": "#ffc700",
              "border-radius": "0.4rem",
              "-webkit-font-smoothing": "antialiased",
              "line-height": "normal",
              "padding": "1.5em 2.505em",
              "font-family": "inherit",
              "font-weight": "500",
              "font-style": "normal",
              "text-transform": "none"}}  />
          </div>
        </form>

        var content = ""

        if (this.state.feedbackSubmitted)
        {
          content = 
            <div>
              <div style={{"fontSize": "26px"}}>
                Campfire is developed by a small & passionate team to make remote connections more human. We want to do our best to improve your next experience. 
                <br></br>
                <br></br>
                We'd love to chat about your experience- please book <a style={{"color": "#ffc700"}} href="https://calendar.campfire.to/sinazargaran/user-interview">a time here 😊</a>!
              </div>
            </div>
        }
        else if (this.props.starsRating == 4) {
          content = 
            <div>
              <div style={{"fontSize": "30px"}}>
                Thank you for your feedback! Please share anything that could improve the experience:
              </div>
              <br></br>
              {feedbackForm}
            </div>
        } 
        else if (this.props.starsRating == 3) {
          content = 
          <div>
            <div style={{"fontSize": "30px"}}>
              Sounds like you might've had some issues! Please help us improve with your feedback:
            </div>
            <br></br>
            {feedbackForm}
          </div>

        } 
        else if (this.props.starsRating < 3 && this.props.starsRating > -1) {
          content = 
          <div>
            <div style={{"fontSize": "30px"}}>
              Sorry about your experience! Tell us what went wrong:
            </div>
            <br></br>
            {feedbackForm}
          </div>
        }
        else {
          var stars = []
          for (var i = 0; i < 5; i++) {
            let index = i
            var color = index <= this.state.starHover ? "orange": "white";
            stars.push(
              <span
                key={i}  
                style={{"color": color}}
                onMouseOver={() => this.setState({starHover: index})}
                onMouseOut={() =>this.setState({starHover: -1})}
                onClick={() => this.props.rateApp(index)}
                className="fa fa-star"> </span>
            )
          } 
  
          content =
            <div>
                Thank you for using Campfire!
                <br></br>
                <br></br>
                How was your experience?
                <div style={{"marginTop": "100px", "fontSize": "40px", "letterSpacing": "10px"}}>
                    {stars}              
                </div>
            </div>
        }
  
        let postLeaveSurvey =
            <div style={{background: "black", "fontWeight": "bold", color: "white", width: "100%", height: "100%", position: "fixed", display: "inline", zIndex: 10}}>
                <div style={{width: "500px", height: "500px", position: "fixed",
                left: "50%", top: "50%", "marginTop": "-250px", "marginLeft": "-250px", "textAlign": "center", "fontSize": "24px"}}>
                    {content}
                </div>
            </div>

      return (
        <div>
          {postLeaveSurvey}
        </div>
      );
    }
  }
  
  export { DidLeaveUnity }