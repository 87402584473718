// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
	getAuth,
	GoogleAuthProvider,
	signInWithPopup,
	linkWithCredential,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	sendEmailVerification,
	sendPasswordResetEmail,
	fetchSignInMethodsForEmail,
	linkWithPopup,
	FacebookAuthProvider
} from "firebase/auth";
import db from "./RealtimeDb";
import {signInAnonymously, updateCurrentUser} from "@firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBUKrsvFHaUiQUioaJ5TwDyj2WKe0jHJko",
	authDomain: "campfire-db4a2.firebaseapp.com",
	databaseURL: "https://campfire-db4a2-default-rtdb.firebaseio.com",
	projectId: "campfire-db4a2",
	storageBucket: "campfire-db4a2.appspot.com",
	messagingSenderId: "902374396652",
	appId: "1:902374396652:web:92afa9362d337eaed6cbc9",
	measurementId: "G-7HBR8GRRYN"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;

export const auth = getAuth(firebaseApp);

const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export function signInWithGooglePopup(callback) {
	signInWithPopup(auth, provider).then((result) => {
		callback(result, null);
	}).catch((e) => {
		callback(null, e.code);
	});
};

export function signInAsGuest(callback){
	signInAnonymously(auth).then().then((result) => {
		callback(result, null);
	}).catch((error) => {
		callback(null, error.code);
	});
}

export function signInWithFacebookPopup(callback) {
	signInWithPopup(auth, facebookProvider).then((result) => {
		result.user.emailVerified = true;
		callback(result, null);

	}).catch((error) => {
		if (error.code === 'auth/account-exists-with-different-credential') {
			let pendingCred = FacebookAuthProvider.credentialFromError(error);
			fetchSignInMethodsForEmail(auth, error.customData.email).then(function(methods) {
				if (methods[0] !== 'password') {
					signInWithPopup(auth, provider).then((result) => {
						linkWithCredential(result.user, pendingCred).then((result) => {
						callback(result, null);
						}).catch((e)=> {
							callback(null, e.code);
						})
					}).catch((e) => {
						callback(null, e.code);
					});
				}
			});
		}else{
			callback(null, error.code);
		}
	});

};

export function signInCampfireUserWithEmailAndPassword(email, password, callback) {
	signInWithEmailAndPassword(auth, email, password).then((res) => {
		if (!res.user.emailVerified)
		{
			const errorMsg = "Email is not verified";
			sendEmailVerification(res.user).catch(e => {
				console.log("TOO MANY EMAIL VERIF REQUESTS");
			});
			auth.signOut();
			callback(null, errorMsg);
			return;
		}
		callback(res, null);
	}).catch((e) => {
		const errorCode = e.code;
		const errorMsg = e.message;
		console.log("ERROR SIGNING IN");
		console.log(e.code);
		console.log(e.message);
		callback(null, errorCode);
	})
}

export function signOut(callback) {
	auth.signOut().then(() => {
		// Sign out succesful
		console.log("signout success");
		callback("Sucess", null);
	}).catch((error) => {
		// Sign out fail
		console.log("signout failed " + error);
		callback(null, error);
	}); 
}

export function createCampfireUserWithEmailAndPassword(email, password, callback) {
	createUserWithEmailAndPassword(auth, email, password).then((res) => {
		sendEmailVerification(res.user);
		callback(res, null);
	}).catch((e) => {
		const errorCode = e.code;
		const errorMsg = e.message;
		console.log("ERROR CREATING");
		console.log(e.code);
		console.log(e.message);
		callback(null, errorCode);
	})
}

export function sendCampfirePasswordResetLink(email, callback) {
	sendPasswordResetEmail(auth, email).then(() => {
		callback("Success", null);
	}).catch((e) => {
		callback(null, e.code);
	});
}