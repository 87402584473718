import firebaseApp from './FirebaseApp'
import { on, ref, set, get, child, push, remove, onValue, getDatabase, onDisconnect, update } from "firebase/database";


// const crypto = require('crypto');

const firebaseDb = getDatabase(firebaseApp)

class Database {

    listeners = new Map()

    // https://firebase.google.com/docs/database/web/offline-capabilities
    PresenceStarted(sessionId, name, team, room) {
      const presencePath = `occupancy/${team}/rooms/room_${room}/sessions/${sessionId}`
      const presenceRef = ref(firebaseDb, presencePath)

      // This registers a server disconnect action. Which means that if the user
      // loses connection to the server, the remove is automatically executed server-side
      onDisconnect(presenceRef).remove();
      set(presenceRef, {"name": name});
    }

    PresenceComplete(sessionId, team, room) {
      const presencePath = `occupancy/${team}/rooms/room_${room}/sessions/${sessionId}`
      const presenceRef = ref(firebaseDb, presencePath)

      for (let [commandId, listener] of this.listeners)
      {
        listener();
      }

      this.listeners = new Map();

      set(presenceRef, null)
    }

    Get(refPath, callback) {
      get(ref(firebaseDb, refPath))
        .then((snapshot) => {
            var data = snapshot.val();
            callback(data, null)
        })
        .catch((error) => {
            var err = `${error}`
            callback(null, err)
        });
    }

    async GetAsync(refPath) {
      var success;
      var result;

      await get(ref(firebaseDb, refPath))
      .then((snapshot) => {
        success = true;
        result = snapshot.val();
      })
      .catch((error) => {
        success = false;
        result = `${error}`;
      });

      return [success, result];
    }

    Set(refPath, data, callback) {
        set(ref(firebaseDb, refPath), data)
            .then(() => {
                callback(data, null)
            })
            .catch((error) => {
                var err = `${error}`
                callback(null, err)
            });
    }
    
    Update(refPath, updates, callback) {
        update(ref(firebaseDb, refPath), updates)
            .then(() => {
                callback(updates, null);
            })
            .catch((error) => {
                var err = `${error}`
                callback(null, err)
            });
    }

    OnValue(refPath, callId, callback) {

      let onValueRef = ref(firebaseDb, refPath)
      const listener = onValue(onValueRef, (snapshot) => {
        var data = snapshot == null ? null : snapshot.val();
        callback(data, null)
      });

      this.listeners.set(callId, listener)
    }

    RemoveOnValue(callId) {
      var listener = this.listeners.get(callId);
      this.listeners.delete(callId);
      if (listener != null)
      {
        listener();
        return true;
      }
      return false;
    }
}

let db = new Database(firebaseDb)
export default db

